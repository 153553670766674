/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaTwitterSquare, FaInstagramSquare, FaEnvelope } from "react-icons/fa"
import { IconContext } from "react-icons/lib"
import { Row, Col } from "react-bootstrap"

const BioAuthorPage = ({author}) => {
  const avatarUrl = author?.avatar?.url.replace('-96x96','');

  return (
    <div>
      <h3 style={{textAlign: "center"}}><strong>{author.name}</strong></h3>
      {author?.userAdditionalInfo?.showbio && (
        <div className="bio-author-page">
        <div style={{maxWidth: "47em", margin: "auto", padding: "0em 1em 0em 1em"}}>
        <Row>
          <Col className="d-flex flex-column align-items-center align-self-center">
            {avatarUrl && (
              <img
                alt={author?.name || ``}
                className="bio-avatar-author-page"
                src={avatarUrl}
              />
            )}
          </Col>
          <Col md={9}>
            <div style={{textAlign: "center"}}>
              {author.userAdditionalInfo.twitter && (
                <a href={`https://twitter.com/${author.userAdditionalInfo.twitter}`}>
                  <IconContext.Provider value={{ title: "Twitter", color: "#2e353f", size: "1.9em", style: { paddingLeft: '0.5em' } }}>
                    <FaTwitterSquare/> 
                  </IconContext.Provider> 
                </a>
              )}
              {author.userAdditionalInfo.instagram && (
                <a href={`https://instagram.com/${author.userAdditionalInfo.instagram}`}>
                  <IconContext.Provider value={{ title: "Instagram", color: "#2e353f", size: "1.9em", style: { paddingLeft: '0.5em' } }}>
                    <FaInstagramSquare/>
                  </IconContext.Provider> 
                </a>
              )}
              {author.userAdditionalInfo.publicemail && (
                <a href={`mailto://${author.userAdditionalInfo.publicemail}`}>
                  <IconContext.Provider value={{ title: "Email", color: "#2e353f", size: "1.9em", style: { paddingLeft: '0.5em' } }}>
                    <FaEnvelope/>
                  </IconContext.Provider> 
                </a>
              )}
            </div>
              <p>
                {author?.description || null}
                {` `}
              </p>
            </Col>
        </Row>
        </div>
        </div>
      )}
    </div>
  )
}

export default BioAuthorPage
