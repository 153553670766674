import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import BioAuthorPage from "../components/bio-author-page"
import Layout from "../components/layout"
import Seo from "../components/seo"

const articles_page_author = ({
  data,
  pageContext: { nextPagePath, previousPagePath, author },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title = {author.name}/>
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title="All posts" />
        <BioAuthorPage author={author}/>
      
      <div class="news-wrap">
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.title

          return (
            <li key={post.uri}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.uri} itemProp="url">
                      <span itemProp="headline">{parse(title)}</span>
                    </Link>
                  </h2>
                  <small>{post.date}</small>
                </header>
                <section itemProp="description">{parse(post.excerpt)}</section>
              </article>
              <hr></hr>
            </li>
          )
        })}
      </ol>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
        </>
      )}
      {nextPagePath && <Link style={{float:'right'}} to={nextPagePath}>Next page</Link>}
      </div>
    </Layout>
  )
}

export default articles_page_author

export const pageQuery = graphql`
  query WordPressAuthorPostArchive($offset: Int!, $postsPerPage: Int!, $authorId: ID!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {authorId: {eq: $authorId}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
      }
    }
  }
`